<template>
  <div class="title">专项标物</div>

  <pro-cate-list
    :list="list"
    :children="children"
    @handleclick="onClick"
    @handlesecclick="onClickSecond"
  ></pro-cate-list>
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from "vue";
import ProCateList from "@/components/Product/List.vue";
import api from "@/api/axios";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "specialProCategory",
  components: {
    ProCateList,
  },
  setup() {
    const list = ref([]);
    const children = ref([]);

    api
      .get("/M/Product/getAllSpecialProClassify")
      .then((res) => {
        list.value = res.data.obj.one;
        res.data.obj.two.unshift({
          id: 0,
          categoryCode:0,
          categoryPcode:(list.value as any)[0].categoryCode,
          columnType:(list.value as any)[0].columnType,
          categoryCnname: "全部",
        });
        children.value = res.data.obj.two;
      })
      .catch((err) => {
        console.log(err);
      });
    const router = useRouter();
    function onClick(classId: string ,columnType: string) {
      api
        .get("/M/Product/getAllSpecialProClassify", {
          params: {
            type: 2,
            id: classId,
            columnType:columnType
          },
        })
        .then((res) => {
          res.data.obj.two.unshift({
            id: 0,
            categoryCode:0,
            categoryPcode:classId,
            columnType:columnType,
            categoryCnname: "全部",
          });
          children.value = res.data.obj.two;
        })
        .catch((err) => {
          console.log(err);
        });
    }
    function onClickSecond(anclassid: string, nclassid: number, columnType: number) {
      router.push({
        name: "specialProduct",
        query: {
          anclassid,
          nclassid,
          columnType,
        },
      });
    }
    return {
      list,
      children,
      onClick,
      onClickSecond,
    };
  },
});
</script>


<style lang="scss" scoped>
.title {
  color: #333;
  text-align: center;
  font-size: 16px;
  line-height:1.5;
  padding: 7px 0;
  border-bottom: 1px solid #f4f4f4;
}
</style>
